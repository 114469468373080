import React, { useState } from "react";
import { ViewLiveViewNotFound } from "./ViewliveViewNotFound";
import './cctv.css';
import View from "./VideoView";

export default function VideoWall() {
    const [id, setId] = useState(null);
    const [channel, setChannel] = useState(null);

    const handleDisplayVideo = () => {
        setId('4fa9a51c-e904-4ab0-acad-169ed4c9aedc');
        setChannel('Sub');
    };

    return (
        <div className="cctv">
            <div onClick={() => {
                handleDisplayVideo()
            }}>
                {!id && <ViewLiveViewNotFound />}
                {id && <View id={id} channel={channel} />}
            </div>
        </div>
    );
}