import './App.css';
import VideoWall from './CCTV';

function App() {
  return (
    <div>
      <VideoWall />
    </div>
  );
}

export default App;
